/**
 * External dependencies
 */
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';

/**
 * Internal dependencies
 */
import useModal from './modal-manager';
import { useHeader } from '@/hosts-marketplace/blocks/header/use-header';

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keydown';

const ModalManagerContent = ({ lastClassName, modalManagerProps, lastModal }) => {
    const { closeModal, metadata, stackModals } = useModal();
    // const { isFolded } = useHeader();

    const lastModalMetaData = metadata[modalManagerProps.id];

    const handleEscKey = useCallback(
        (event) => {
            if (event.key === KEY_NAME_ESC) {
                if (typeof modalManagerProps?.onEscapeKeyPress === 'function') {
                    modalManagerProps.onEscapeKeyPress();
                } else if (typeof lastModalMetaData?.onEscapeKeyPress === 'function' && !stackModals?.length) {
                    lastModalMetaData.onEscapeKeyPress();
                } else {
                    closeModal();
                }
            }
        },
        [closeModal, metadata, modalManagerProps]
    );

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);

    const handleOverlayClick = () => {
        if (typeof modalManagerProps?.onOverlayClick === 'function') {
            modalManagerProps.onOverlayClick();
        } else if (typeof lastModalMetaData?.onOverlayClick === 'function' && !stackModals?.length) {
            lastModalMetaData.onOverlayClick();
        } else {
            closeModal();
        }
    };

    return (
        <div
            className={classNames('sticky-holder', {
                [modalManagerProps?.globalClassName]: modalManagerProps?.globalClassName,
            })}
        >
            <div
                // className={classNames('sticky-holder__inner', {
                //     'header-folded': isFolded,
                // })}
                className="sticky-holder__inner"
            >
                <div className={lastClassName} style={modalManagerProps?.style || {}}>
                    <div className="modal__dialog">
                        <div className="modal__content">
                            {modalManagerProps?.header && (
                                <div className="modal__header">{modalManagerProps.header}</div>
                            )}
                            <div className="modal__body">{lastModal}</div>
                        </div>
                    </div>
                </div>

                {!modalManagerProps?.isOverlayOutside && (
                    <div className="sticky-holder__overlay" onClick={handleOverlayClick} />
                )}
            </div>

            {modalManagerProps?.isOverlayOutside && (
                <div className="sticky-holder__overlay" onClick={handleOverlayClick} />
            )}
        </div>
    );
};

export default ModalManagerContent;
