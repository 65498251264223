/**
 * External dependencies
 */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { router } from '@inertiajs/react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isFunction, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

/**
 * Internal dependencies
 */
import ModalManagerContent from './modal-manager-content';

const ModalContext = createContext({});

const DELAY = 200;

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);
    const [stackModals, setStackModals] = useState([]);
    const [metadata, setMetadata] = useState({});
    const lastModal = modals[modals.length - 1];
    const modalManagerProps = lastModal?.props?.modalManager;

    const onAfterCloseModal = modalManagerProps?.onAfterCloseModal;
    const isFunctionOnAfterCloseModal = isFunction(onAfterCloseModal);

    const onBeforeRouteChange = modalManagerProps?.onBeforeRouteChange;
    const isFunctionOnBeforeRouteChange = isFunction(onBeforeRouteChange);

    const openModal = (content) => {
        setModals((items) => [...items, content]);
    };

    const openStackModal = (content) => {
        setStackModals((items) => [...items, content]);
    };

    const closeModal = () => {
        if (!isEmpty(stackModals)) {
            setStackModals((items) => items.slice(0, items.length - 1));
        } else {
            setModals((items) => items.slice(0, items.length - 1));
        }
        isFunctionOnAfterCloseModal && onAfterCloseModal();
    };

    const closeAll = () => {
        setModals([]);
        setStackModals([]);
    };

    const closeAllStackedModals = () => {
        setStackModals([]);
    };

    const setModalMetadata = useCallback((modalId, data) => setMetadata((prev) => ({ ...prev, [modalId]: data })), []);

    useEffect(() => {
        if (modals.length || stackModals.length) {
            document.documentElement.classList.add('overflow-hidden');
        } else {
            document.documentElement.classList.remove('overflow-hidden');
        }
    }, [modals, stackModals]);

    useEffect(() => {
        let beforeRouteChange;

        if (isFunctionOnBeforeRouteChange) {
            beforeRouteChange = router.on('before', (event) => {
                // control whether to proceed with navigation event externally:
                // by returning TRUE|FALSE
                return onBeforeRouteChange(event);
            });
        }

        const onRouterNavigate = router.on('navigate', () => closeAll());

        return () => {
            onRouterNavigate();
            isFunctionOnBeforeRouteChange && beforeRouteChange();
        };
    }, [onBeforeRouteChange, isFunctionOnBeforeRouteChange]);

    const contextValue = {
        modals,
        openModal,
        stackModals,
        metadata,
        setModalMetadata,
        openStackModal,
        closeModal,
        closeAll,
        closeAllStackedModals,
    };

    return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const ModalManager = ({ isGlobalManager }) => {
    const { modals, stackModals } = useModal();

    if (!modals) {
        return;
    }

    const lastModal = modals[modals.length - 1];
    const lastStackModal = stackModals[stackModals.length - 1];
    const modalManagerProps = lastModal?.props?.modalManager || lastModal?.props?.modalmanager;
    const lastClassName = classNames('modal', modalManagerProps?.className);

    const stackModalManagerProps = lastStackModal?.props?.modalManager || lastStackModal?.props?.modalmanager || {};
    const lastStackModalClassName = classNames('modal', stackModalManagerProps?.className);

    const isGlobalModal = modalManagerProps?.renderGlobal;

    const shouldRender = isGlobalManager ? isGlobalModal : !isGlobalModal;

    return (
        <TransitionGroup>
            {lastModal && shouldRender && (
                <CSSTransition
                    timeout={DELAY}
                    in={lastModal !== undefined}
                    key={lastModal.type.name || modalManagerProps?.key || modals.length}
                    classNames={{
                        appear: 'is-open',
                        appearActive: 'is-open',
                        appearDone: 'is-open',
                        enter: 'is-open',
                        enterActive: 'is-open',
                        enterDone: 'is-open',
                    }}
                    unmountOnExit
                >
                    <ModalManagerContent
                        lastClassName={lastClassName}
                        modalManagerProps={modalManagerProps}
                        lastModal={lastModal}
                    />
                </CSSTransition>
            )}

            {lastStackModal && shouldRender && (
                <CSSTransition
                    timeout={DELAY}
                    in={lastStackModal !== undefined}
                    key={uuidv4()}
                    classNames={{
                        appear: 'is-open',
                        appearActive: 'is-open',
                        appearDone: 'is-open',
                        enter: 'is-open',
                        enterActive: 'is-open',
                        enterDone: 'is-open',
                    }}
                    unmountOnExit
                >
                    <ModalManagerContent
                        lastClassName={lastStackModalClassName}
                        modalManagerProps={{
                            ...stackModalManagerProps,
                            globalClassName: classNames('stacked-modal', stackModalManagerProps?.globalClassName),
                        }}
                        lastModal={lastStackModal}
                    />
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
};

export default useModal;
